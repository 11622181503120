<template>
  <section id="settings">
    <h1 id="page-title">{{ $t('settings') }}</h1>
    <b-row class="row-wide">
      <b-col lg="auto">
        <div class="spacecard left-sideblock sm spacecard-menu" >
          <router-link class="d-block"
                       v-for="(m,mind) in menu"
                       :to="m.to"
                       :key="'menu-p-'+mind">{{ $t(m.title) }}
          </router-link>
          <a class="d-block pointer" v-if="showTest" @click="$bvModal.show('testinternetmodal')">{{ $t('test_connection') }}</a>
        </div>
        <a class="spacecard sm d-block mt-3 text-center pointer text-danger" @click="logout">{{ $t('logout') }}</a>
      </b-col>
      <b-col lg>
        <div class="spacecard" >
          <router-view/>
        </div>
      </b-col>
    </b-row>
    <InternetAndDevicesTestModal/>
  </section>
</template>

<script>
import AuthService from "@/services/auth.helper"
import InternetAndDevicesTestModal from "../../../parts/class/InternetAndDevicesTestModal"

export default {
  components: {InternetAndDevicesTestModal},
  data() {
    return {
      menu: [
        { to: "/student/settings/profile", title: "profile" },
        { to: "/student/settings/subscription", title: "subscription" }
      ],
      showTest: true
    }
  },
  metaInfo() {
    return {
      title: this.$t('settings'),
    }
  },
  mounted() {
  },
  methods: {
    logout() {
      this.$confirm(this.$t('are_you_sure_you_want_to_logout')).then(() => {
        AuthService.logout()
        this.$router.push('/login')
      })

    }
  }
}
</script>


